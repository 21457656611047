import * as React from "react";
import type { HeadFC } from "gatsby";
import { useForm, SubmitHandler } from "react-hook-form";
import logo from "../images/transparent.png";
import { SEO } from "../components/SEO";

type Inputs = {
  email: string;
};

const IndexPage = () => {
  const [submitted, setSubmitted] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = ({ email }) => {
    const url = "https://www.api.talonbot.com/v1/product/talon/waitlists";

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        email,
      }),
    };

    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        setSubmitted(true);
      })
      .catch((e) => console.error("Error submitting form: ", e));
  };

  return (
    <main className="h-screen bg-body text-paragraph p-4 text-center pt-36">
      <div>
        <section>
          <img className="max-h-[100px] inline" src={logo} alt="Logo" />
          <h1 className="my-2">TALON</h1>
          <p className="text-lg">The premier auto-checkout bot.</p>
        </section>
        <section className="pt-12">
          <p className="mb-8">
            We will be running a closed beta in the coming weeks. Please
            register your interest below.
          </p>
          {!submitted && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-wrap align-middle justify-center">
                <input
                  {...register("email", { required: true })}
                  className="py-2 px-4 border-0 rounded-sm sm:mr-2 bg-card text-heading text-lg"
                  type="email"
                  placeholder="Enter your email"
                />
                <button
                  type="submit"
                  className="bg-primary text-heading rounded-sm font-semibold cursor-pointer py-2 px-4 tracking-widest text-lg border-0"
                >
                  SUBMIT
                </button>
              </div>
              {errors.email && (
                <span className="text-error text-sm">
                  You must enter an email address
                </span>
              )}
            </form>
          )}
          {submitted && (
            <p className="mt-8 text-tertiary">
              Your interest has been noted. You should hear from us soon.
            </p>
          )}
        </section>
      </div>
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <SEO />;
